import React from "react";
import "/assets/styles/home/carousel.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "@coreui/coreui/dist/css/coreui.min.css";

import slide1 from "../../../../public/images/homepage/blogCarousel1.png";
import slide2 from "../../../../public/images/homepage/blogCarousel2.png";

const handleDragStart = (e) => e.preventDefault();

const items = [
    /* A React component. */
    <div className="item">
        <img
            src={slide1}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="item_content _dark">
            <strong>Blog :</strong>
            <p>
                Notre blog est une extension de notre engagement en tant que centre de recherche
                à but non lucratif déidé à la durabilité, à l'économie verte et circulaire, et à
                l'empowerment dans la lutte contre le changement climatique.
            </p>
            <button><a href="">Inscription &gt;</a></button>
        </div>
    </div>,
    /* A React component. */
    <div className="item">
        <img
            src={slide2}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="secondItem_content">
            <strong>NewsLetter :</strong>
            <p>
                Tous les 3 mois, la newsletter de gaea21 relate les évènements et décrit les activités de notre organisation
            </p>
            <button><a href="/newsletter#section-newsletter">Inscription &gt;</a></button>
        </div>
    </div>,


];


const CarouselWithLibrary = () => {
    return (
        <AliceCarousel
            items={items}
            autoPlayInterval={10000}
            // autoPlay={true}
            // infinite={true}
            disableButtonsControls={true}
            autoPlayStrategy='none'
            syncStateOnPropsUpdate={true}
            animationDuration={1000}>
        </AliceCarousel>
    );
};

export default CarouselWithLibrary;
