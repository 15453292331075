import React from "react";
import "/assets/styles/home/carousel.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "@coreui/coreui/dist/css/coreui.min.css";

const handleDragStart = (e) => e.preventDefault();

const items = [

    <div className={"fill_parent_container"}>
        <div className={"agenda_item"}>
            <div className={"agenda_item_top"}>
                <div className={"agenda_item_date"}>
                    <h3>MAR</h3>
                    <h2>2</h2>
                </div>
                <div className={"agenda_item_state"}>
                    <h3>Sur place</h3>
                </div>

                <div className={"agenda_item_content"}>
                    <h3>Festival du Film Vert</h3>
                    <span>Présenté par : Les Films Verts</span>

                    <p>Découvrez la 19e édition du 2 mars au 14 avril !</p>
                    <a href="https://www.festivaldufilmvert.ch/" target="_balnk">En savoir plus</a>
                </div>
            </div>

        </div>
    </div>,
    <div className={"fill_parent_container"}>
        <div className={"agenda_item"}>
            <div className={"agenda_item_top"}>
                <div className={"agenda_item_date"}>
                    <h3>MAR</h3>
                    <h2>22</h2>
                </div>
                <div className={"agenda_item_state"}>
                    <h3>Sur place</h3>
                </div>

                <div className={"agenda_item_content"}>
                    <h3>Green drinks</h3>
                    <span>Présenté par : gaea21</span>
                    <p>Venez découvrir l'équipe gaea21 autour d'un verre.</p>
                    <a href="https://www.festivaldufilmvert.ch/" target="_balnk">En savoir plus</a>
                    {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScQ-vCDw0MWqb_4M7Rf5uX4lajY_tu1TQDFTehUTrAi0hbZng/viewform" target="_balnk">Je m'inscris</a> */}

                </div>

            </div>

        </div>
    </div>,
    <div className={"fill_parent_container"}>
        <div className={"agenda_item"}>
            <div className={"agenda_item_top"}>
                <div className={"agenda_item_date"}>
                    <h3>MAR</h3>
                    {/* <h2></h2> */}
                </div>
                <div className={"agenda_item_state"}>
                    <h3>En ligne</h3>
                </div>

                <div className={"agenda_item_content"}>
                    <h3>Répertoire Vert</h3>
                    <span>Présenté par : gaea21</span>
                    <p>Lancement officiel sur apple et android.</p>
                    <a href="https://www.festivaldufilmvert.ch/" target="_balnk">En savoir plus</a>
                    {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfvwaDRdToMsPbSAjv5wQ5NOgW9-CSTYyJjuFnSXC_Ba24AQg/viewform" target="_balnk">Je m'inscris</a> */}

                </div>

            </div>

        </div>
    </div>,
    <div className={"fill_parent_container"}>
        <div className={"agenda_item"}>
            <div className={"agenda_item_top"}>
                <div className={"agenda_item_date"}>
                    <h3>FEV</h3>
                    <h2>16</h2>
                </div>
                <div className={"agenda_item_state"}>
                    <h3>En ligne</h3>
                </div>

                <div className={"agenda_item_content"}>
                    <h3>Yvane couture</h3>
                    <span>Présenté par : gaea21</span>
                    <p>Lancement du site Yvane couture !</p>
                    <a href="https://yvanecouture.com/" target="_blank">Je m'inscris</a>
                </div>

            </div>

        </div>
    </div>,
    <div className={"fill_parent_container"}>
        <div className={"agenda_item"}>
            <div className={"agenda_item_top"}>
                <div className={"agenda_item_date"}>
                    <h3>FEV</h3>
                    {/* <h2></h2> */}
                </div>
                <div className={"agenda_item_state"}>
                    <h3>En ligne</h3>
                </div>

                <div className={"agenda_item_content"}>
                    <h3>Observatoire gaea21</h3>
                    <span>Présenté par : gaea21</span>
                    <p>Première publication ! Découvrez notre action dans le cadre du Green Coaching au Mali.</p>
                    {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfvwaDRdToMsPbSAjv5wQ5NOgW9-CSTYyJjuFnSXC_Ba24AQg/viewform" target="_balnk">Je m'inscris</a> */}

                </div>

            </div>
            {/* <div className={"agenda_item_bottom"}>
                <button className={"agenda_item_bottom_button"}>❯</button>
            </div> */}
        </div>
    </div>,

    // <div className={"fill_parent_container"}>
    //     <div className={"agenda_item"}>
    //         <div className={"agenda_item_top"}>
    //             <div className={"agenda_item_date"}>
    //                 <h3>JAN</h3>
    //                 <h2>12</h2>
    //             </div>
    //             <div className={"agenda_item_state"}>
    //                 <h3>En ligne</h3>
    //             </div>

    //             <div className={"agenda_item_content"}>
    //                 <h3>Yvane couture</h3>
    //                 <span>Présenté par : gaea21</span>
    //                 <p>Lancement du site développé par gaea21 pour Yvane une talentueuse couturière irlandaise.</p>
    //                 {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScQ-vCDw0MWqb_4M7Rf5uX4lajY_tu1TQDFTehUTrAi0hbZng/viewform" target="_balnk">Je m'inscris</a> */}

    //             </div>

    //         </div>
    //         <div className={"agenda_item_bottom"}>
    //             <button className={"agenda_item_bottom_button"}>Voir plus d'événements ❯</button>
    //         </div>
    //     </div>
    // </div>,

];


const CarouselWithLibrary = () => {
    return (
        <>
            <AliceCarousel
                items={items}
                mouseTrackingEnabled={true}
                autoWidth={true}
                autoHeight={true}
                disableDotsControls={true}
                renderPrevButton={() => <div className={"agenda_item_prev"}>❮</div>}
                renderNextButton={() => <div className={"agenda_item_next"}>❯</div>}
            />
            <div className="agenda_item_bottom">
                <a className="agenda_item_bottom_button" href="#">
                    <span className="button-text">Voir plus d'événements</span>
                    <span className="button-icon">❯</span>
                </a>
            </div>
        </>
    );
}
export default CarouselWithLibrary;
