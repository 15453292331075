import React from "react";
import "../../../styles/home/carousel.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "@coreui/coreui/dist/css/coreui.min.css";

import slide1 from "../../../../public/Image/slide/slide1.jpg";
import slide2 from "../../../../public/Image/slide/slide2.jpg";
import slide3 from "../../../../public/Image/slide/slide3.jpg";
import slide4 from "../../../../public/Image/slide/slide4.jpg";
import slide5 from "../../../../public/Image/slide/slide5.jpg";

const handleDragStart = (e) => e.preventDefault();

const items = [
    /* A React component. */
    <div className="itemCarousel">
        <img
            src={slide1}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="item_content">
            <h4>Générateurs de durabilité</h4>
            <hr />
            <p>
                Nous soutenons vos projets et réalisons vos idées « vertes ». Nous formons, mettons en relation et accompagnons individus, entreprises et organisations.
                <br />
                « Vert » est pour nous synonyme d’éco-conscience, de choix et de comportements durables, efficaces et profitables.
            </p>
        </div>
    </div>,
    /* A React component. */
    <div className="itemCarousel">
        <img
            src={slide2}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="item_content">
            <h4>Générateurs de durabilité</h4>
            <hr />
            <p>
                Nous soutenons vos projets et réalisons vos idées « vertes ». Nous formons, mettons en relation et accompagnons individus, entreprises et organisations.
                <br />
                « Vert » est pour nous synonyme d’éco-conscience, de choix et de comportements durables, efficaces et profitables.
            </p>
        </div>
    </div>,
    /* A React component. */
    <div className="itemCarousel">
        <img
            src={slide3}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="item_content">
            <h4>Générateurs de durabilité</h4>
            <hr />
            <p>
                Nous soutenons vos projets et réalisons vos idées « vertes ». Nous formons, mettons en relation et accompagnons individus, entreprises et organisations.
                <br />
                « Vert » est pour nous synonyme d’éco-conscience, de choix et de comportements durables, efficaces et profitables.
            </p>
        </div>
    </div>,
    /* A React component. */
    <div className="itemCarousel">
        <img
            src={slide4}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="item_content">
            <h4>Générateurs de durabilité</h4>
            <hr />
            <p>
                Nous soutenons vos projets et réalisons vos idées « vertes ». Nous formons, mettons en relation et accompagnons individus, entreprises et organisations.
                <br />
                « Vert » est pour nous synonyme d’éco-conscience, de choix et de comportements durables, efficaces et profitables.
            </p>
        </div>
    </div>,
    /* A React component. */
    <div className="itemCarousel">
        <img
            src={slide5}
            onDragStart={handleDragStart}
            role="presentation"
        />
        <div className="item_content">
            <h4>Générateurs de durabilité</h4>
            <hr />
            <p>
                Nous soutenons vos projets et réalisons vos idées « vertes ». Nous formons, mettons en relation et accompagnons individus, entreprises et organisations.
                <br />
                « Vert » est pour nous synonyme d’éco-conscience, de choix et de comportements durables, efficaces et profitables.
            </p>
        </div>
    </div>
];


const CarouselWithLibrary = () => {
    return (
        <AliceCarousel
            items={items}
            autoPlayInterval={10000}
            autoPlay={true}
            infinite={true}
            disableButtonsControls={true}
            autoPlayStrategy='none'
            syncStateOnPropsUpdate={true}
            animationDuration={1000}>
        </AliceCarousel>
    );
};

export default CarouselWithLibrary;
