import React, { useState, useEffect } from "react";
import "/assets/styles/home/carousel.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "@coreui/coreui/dist/css/coreui.min.css";

import rvHomepage from "../../../../public/Image/diap/rvHomepage.png";
import diap2 from "../../../../public/Image/diap/diap2.png";
import diap3 from "../../../../public/Image/diap/diap3.png";

const handleDragStart = (e) => e.preventDefault();

function CarouselItems({ items }) {
    return items.map((item, index) => (
        <div className="item_diapo" key={index}>
            <img src={item.image} onDragStart={handleDragStart} role="presentation" alt={item.title} />
            <div className="item_diapo_content">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
                <a href="#">En savoir plus</a>
            </div>
            <div className="item_diapo_background" />
        </div>
    ));
}

function CarouselThumb({ item, index, isActive, onClick }) {
    return (
        <div
            className={`item_diapo_thumb ${isActive ? 'active' : 'active'}`}
            onClick={onClick}
        >
            <span>
                <img src={item.image} onDragStart={handleDragStart} role="presentation" alt={item.title} />
                <div className="item_diapo_thumb_content">
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                </div>
            </span>
        </div>
    );
}
const CarouselThumbs = ({ items, activeIndex, onThumbClick }) => {
    return items.map((item, index) => (
        <CarouselThumb
            key={index}
            item={item}
            index={index}
            isActive={activeIndex === index}
            onClick={() => onThumbClick(index)}
        />
    ));
};

function CarouselHighlights() {
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        {
            image: rvHomepage,
            title: "Répertoire Vert",
            text: "",
        },
        {
            image: diap2,
            title: "Green Events Series",
            text: "",
        },
        {
            image: diap3,
            title: "Plateforme des Initiatives",
            text: "",
        },
    ];
    const handleSlideChanged = (e) => {
        setActiveIndex(e.item);
    };

    const handleThumbClick = (index) => {
        setActiveIndex(index);
    };
    return (
        <>
            <div className="item_diapo_thumbs">
                <CarouselThumbs
                    items={items}
                    activeIndex={activeIndex}
                    onThumbClick={handleThumbClick}
                />
            </div>
            <AliceCarousel
                key={activeIndex}
                items={CarouselItems({ items })}
                disableButtonsControls
                autoHeight
                activeIndex={activeIndex}
                onSlideChanged={handleSlideChanged}
                mouseTracking
            />
        </>
    );
}

export default CarouselHighlights;


