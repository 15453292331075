import React from "react";
import { render } from "react-dom";
import CarouselHeader from "./components/CarouselHeader";
import CarouselHighlights from "./components/CarouselHighlights";
import CarouselAgenda from "./components/CarouselAgenda";
import CarouselPartner from "./components/CarouselPartner";
import CarouselNewSite from "./components/CarouselBlog";
import "../../styles/home/home.scss";

function AppHomeHeader() {
    return (
        <>
            <CarouselHeader />
        </>
    );
}

function AppHomeHighlights() {
    return (
        <>
            <CarouselHighlights />
        </>
    );
}

function AppHomeAgenda() {
    return (
        <>
            <CarouselAgenda />
        </>
    );
}

function AppHomePartner() {
    return (
        <>
            <CarouselPartner />
        </>
    );
}

function AppHomeNewSite() {
    return (
        <>
            <CarouselNewSite />
        </>
    );
}
render(<AppHomePartner />, document.querySelector("#partner"));
render(<AppHomeAgenda />, document.querySelector("#agenda"));
render(<AppHomeHighlights />, document.querySelector("#highlights"));
render(<AppHomeHeader />, document.querySelector("#carousel"));
render(<AppHomeNewSite />, document.querySelector("#carouselBlog"));
